import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "@/views/SignIn";
import Home from "@/views/Home";
import { isAuth } from "@/helpers/storage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "signin",
    component: SignIn
  },
  {
    path: "/home",
    name: "home",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "signin" && !isAuth()) {
    next("/");
  } else {
    next();
  }
});

export default router;

import Vue from "vue";
import Vuex from "vuex";
import { FeathersVuex } from "../feathers-client";
import auth from "./store.auth";

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  "./services",
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {
    logo:
      process.env.VUE_APP_LOGO_URL ||
      "https://s3-sa-east-1.amazonaws.com/supermenu.api.new/fe8704856a2e04156bfbdf2d4d1a7c1296531cca4de35a289a9d5edbcdb40b8d.png",
    showSidebar: false,
    synced: false,
    selectedOrder: "",
    enums: {
      RolePermissions: {
        DeliveryWorkbench: 1,
        EstablishmentWorkbench: 2,
        Reports: 4,
        Reviews: 8,
        Orders: 16,
        Menu: 32,
        Offers: 64,
        Coupons: 128,
        Loyalty: 256,
        Customers: 512,
        Tables: 1024,
        DeliveryAreas: 2048,
        Admin: 4096,
      },
      RoleActions: {
        Find: 1,
        Get: 2,
        Create: 4,
        Update: 8,
        Remove: 16,
        All: 32,
      },
      LocationTypes: { Delivery: 1, TakeAway: 2, Establishment: 4, Totem: 8 },
      OrderStatusEnum: {
        PendingApproval: 1,
        Approved: 2,
        OutForDelivery: 4,
        Delivered: 8,
        Cancelled: 16,
        Refused: 32,
        Refunded: 64,
        AtEstablishment: 128,
        WaitingForFinish: 256,
        Finished: 512,
        WaitingForDelivery: 1024,
        WaitingForWaiter: 2048,
      },
      ItemStatusEnum: { Pending: 1, Approved: 2, Done: 4, Canceled: 8 },
      CancellationTypeEnum: { Withdrawal: 1, Delay: 2, Mistake: 4, Other: 8 },
      DiscountTypes: { Percentage: 1, "Fixed value": 2 },
      PaymentTypes: { Cash: 1, Online: 2, Offline: 4 },
      CouponTypes: { Offer: 1, Gift: 2 },
    },
  },
  getters: {
    isSynced: (state) => state.synced,
    isSignedIn: (state) =>
      state.auth.accessToken != null &&
      state.auth.user != null &&
      state.auth.accessToken != undefined &&
      state.auth.user != undefined,
    getCompanyId: (state) => state.auth.payload.payload.companyId,
    getSignedUser: (state) => state.auth.user,
    getSelectedOrder: (state) => state.selectedOrder,
    getEnums: (state) => state.enums,
  },
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.showSidebar = !state.showSidebar;
    },
    APP_SYNCED(state) {
      state.synced = true;
    },
    SELECT_ORDER(state, id) {
      state.selectedOrder = id;
    },
    CLEAR_ORDER(state) {
      state.selectedOrder = "";
    },
  },
  actions: {
    toggleSidebar({ commit }) {
      commit("TOGGLE_SIDEBAR");
    },
    sync({ commit }) {
      commit("APP_SYNCED");
      console.log("app synced");
    },
    isSignedIn: ({ state }) =>
      state.auth.accessToken != null &&
      state.auth.user != null &&
      state.auth.accessToken != undefined &&
      state.auth.user != undefined,
    getCompanyId: ({ state }) => state.auth.payload.payload.companyId,
    getSignedUser: ({ state }) => state.auth.user,
    setOrder: ({ commit }, id) => {
      commit("SELECT_ORDER", id);
    },
    clearOrder: ({ commit }) => {
      commit("CLEAR_ORDER");
    },
  },
  plugins: [...servicePlugins, auth],
});

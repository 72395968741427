<template>
  <div class="signin">
    <custom-card
      :description="
        !this.loading ? 'faça login para continuar' : 'por favor aguarde...'
      "
    >
      <b-form class="mt-5" @submit.prevent="login()">
        <b-form-group>
          <b-form-input
            type="email"
            id="email"
            placeholder="E-mail"
            v-model="email"
            v-show="!hasJwt"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="password"
            type="password"
            placeholder="Senha"
            v-model="password"
            v-show="!hasJwt"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button :disabled="disabled" block class="custom-btn" type="submit">
            <div v-if="loading" class="spinner-border text-white"></div>
            <span v-else>Entrar</span>
          </b-button>
        </b-form-group>
      </b-form>
    </custom-card>
  </div>
</template>

<script>
import Vue from "vue";
import CustomCard from "@/components/Utils/CustomCard.vue";
import Locations from "@/network/locations";
import { get, getAll } from "@/network/rest";
import { isAuth } from "@/helpers/storage";
import { login, autologin } from "@/helpers/auth";
import { populateCompany } from "@/helpers/queries";
import { setActiveCompany, setActiveUser } from "@/helpers/storage";
import { toast } from "@/helpers/toast";

export default {
  name: "signin",
  components: {
    CustomCard,
  },
  async created() {
    if (this.hasJwt) this.login();
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      disabled: false,
    };
  },
  computed: {
    feathers() {
      return this.$FeathersVuex.api;
    },
    hasJwt() {
      return isAuth();
    },
  },
  methods: {
    async login() {
      this.loading = true;
      this.disabled = true;

      try {
        const _login =
          !this.email && !this.password
            ? await autologin(this.$store)
            : await login(this.$store, this.email, this.password);

        console.log("signed in");

        setActiveCompany(_login.payload.companyId);
        setActiveUser(_login.payload.userId);

        this.loading = false;
        this.disabled = false;

        this.$router.push("/home");
      } catch (e) {
        this.loading = false;
        this.disabled = false;

        console.log("não foi possível fazer login");
        console.log(e);
        toast(
          this,
          "Ocorreu um erro",
          "Não foi possível fazer login. Verifique o email e senha e confira se está usando o super user.",
          3000,
          "danger"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

export default Vue => {
  Vue.filter("currency", value => {
    return Number(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  });

  Vue.filter("sortAsc", items => items.sort((a, b) => a.seq - b.seq));

  Vue.filter("sortDesc", items => items.sort((a, b) => b.seq - a.seq));

  Vue.filter("orderClass", (status, statusEnum) => {
    let result;

    for (const key in statusEnum) {
      if (statusEnum[key] === status) result = key;
    }

    return result;
  });
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import loadPlugins from "./plugins";
import loadFilters from "./helpers/filters";
import moment from "moment";

/* plugins e filtros */
loadPlugins(Vue);
loadFilters(Vue);

/* moment em pt-br */
moment.locale("pt-br");

/* vue */

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

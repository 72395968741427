const Axios = require("axios").default;
const ENV_API_URL = process.env.VUE_APP_API_URL ?? "https://api.weapp.com.br";
const apiUrl = ENV_API_URL.includes("supermenu") ? "https://api.weapp.com.br" : ENV_API_URL;
const apiUrlPayments =
  process.env.VUE_APP_API_PAYMENTS_URL || "https://payments.weapp.com.br";

const instance = (accessToken) =>
  Axios.create({
    baseURL: apiUrl,
    timeout: 29000,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

const instancePayments = (accessToken) =>
  Axios.create({
    baseURL: apiUrlPayments,
    timeout: 29000,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

const get = async (accessToken, location) =>
  await instance(accessToken).get(location);

const getAll = async (
  accessToken,
  companyId,
  location,
  branchId,
  createdTime = ""
) => {
  try {
    let result = [];

    const branch = branchId ? `&branch=${branchId}` : "";

    const createdAt = createdTime ? `&createdAt[$gte]=${createdTime}` : "";

    const { data } = await get(
      accessToken,
      location + `?$limit=0&company=${companyId}${branch}${createdAt}`
    );

    const skip = 50;
    const total = data.total;
    const times = Math.ceil(total / skip);

    for (let i = 0; i < times; i++) {
      const { data } = await get(
        accessToken,
        `${location}?$limit=${skip}&$skip=${i *
          skip}&company=${companyId}${branch}${createdAt}`
      );

      data.data.forEach((p) => result.push(p));
    }

    return result;
  } catch (e) {
    console.log(`problema com request em ${location}`);
    console.log(e);

    return null;
  }
};

const patch = async (accessToken, location, data) =>
  await instance(accessToken).patch(location, data);

const post = async (accessToken, location, data) =>
  await instance(accessToken).post(location, data);

const postPayments = async (accessToken, location, data) => {
  const response = await instancePayments(accessToken).post(location, data);
  return response;
};

const remove = async (accessToken, location) =>
  await instance(accessToken).delete(location);

export { get, getAll, patch, post, postPayments, remove };
